<template>
  <Layout @filter="filter" :filterCriteriaProps="filterCriteria">
    <template #menu>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-auto bg-btn" dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item style="cursor: pointer">
            <v-list-item-title v-ripple @click="onButtonPress()">
              ADD
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <v-dialog v-model="AddNotes" max-width="600px">
      <div :key="key">
        <add-notes
          :customer="customer"
          :note="note"
          @note-success="refreshData"
        />
      </div>
    </v-dialog>
    <div class="mt-5">
      <unit-note
        v-for="n in notes"
        :key="n.id"
        :note="n"
        @reload-resource="reloadResource"
        @edit-note="editNote"
      />
    </div>
  </Layout>
</template>

<style scoped>
.bg-btn {
  background: #1d2b58 !important;
  color: white !important;
}
</style>
<script>
import util from "@/util/util";
import UnitNote from "../../UnitNote.vue";
import Layout from "@/components/Contact/PersonalContactsInfos/Layout.vue";
import AddNotes from "../PopupForms/AddNotes.vue";
import Rest from "@/components/shared/rest";

export default {
  components: {
    UnitNote,
    Layout,
    AddNotes,
  },
  data() {
    return {
      key: 0,
      notes: [],
      AddNotes: false,
      customer: null,
      filterCriteria: { length: 1, page: 1, q: "" },
      note: {},
    };
  },

  mounted() {
    this.fetchNotes();
    this.fetchCustomer();
  },
  methods: {
    filter(n) {
      this.filterCriteria = n;
    },
    fetchNotes() {
      util
        .http({
          url: "note/customer/" + this.$route.params.id,
          params: this.filterCriteria,
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            this.notes = res.data.data.data;
            this.filterCriteria.length = res.data.data.last_page;
          }
        });
    },
    fetchCustomer() {
      Rest.getSingle.call(this, {
        resource: "customer",
        id: this.$route.params.id,
      });
    },
    onButtonPress() {
      this.note = {};
      this.AddNotes = true;
      this.key++;
    },
    reloadResource() {
      this.fetchNotes();
    },
    editNote(note) {
      this.note = note;
      this.AddNotes = true;
      this.key++;
    },
    refreshData() {
      this.note = {};
      this.key++;
      this.AddNotes = false;
      this.fetchNotes();
    },
  },
  watch: {
    filterCriteria: {
      handler() {
        this.fetchNotes();
      },
      deep: true,
    },
  },
};
</script>
